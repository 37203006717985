.text{
  display: inline-block;
  font-size: 8vmin;
  line-height: 1.205;
}

.text__first, .text__second{
  position: relative;
}

.text__word{
  opacity: 0;
}

/* Fill button styling */
.frame {
  width: 90%;
  margin: 40px auto;
  text-align: center;
}
button {
  margin: 20px;
  outline: none;
}
.custom-btn {
  width: 350px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #e63369;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  font-size: 18px;
}
/* 5 */
.btn-5 {
  background: #e63369;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-5:hover {
  background: transparent;
  color: #e63369;
   box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
}
.btn-5:before,
.btn-5:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #e63369;
  transition:400ms ease all;
}
.btn-5:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.btn-5:hover:before,
.btn-5:hover:after{
  width:100%;
  transition:800ms ease all;
}

.text__first-bg, .text__second-bg{
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  transform-origin: left;
  transform: scaleX(0);
}

.text__first-bg{
  background-color: #FF89BB;
}

.text__second-bg{
  background-color: #FFB3D1;
}

.text__second{
  margin-left: 8vmin;
}

.restart{
  position: absolute;
  font-size: 12px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border: none;
  border-bottom: 1px dotted grey;
  padding: 0;
  margin: 0 auto 2%;
  cursor: pointer;
  color: grey;
}

.third {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}