.f-block {
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background: #fcf8f8;
  color: #8a8a8a;
  font-family: 'Roboto', sans-serif;

  section {
    padding: 10px 20px;
  }
  
  .text-transition {
    font-size: 22px;
  }
  
  .inline {
    font-size: 22px;
  }
  
  .big {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  * {
    box-sizing: border-box;
  }

  @media (min-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    padding-left: 16px;
    padding-right: 16px
  }
  @media (min-width: 800px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    padding-left: 64px;
    padding-right: 64px;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding-left: 128px;
    padding-right: 128px;
  }
  @media (min-width: 1280px) {
    /* hi-res laptops and desktops */
    padding-left: 256px;
    padding-right: 256px;
  }
}


