.container-hook {
  display: flex;
  flex: 1;
  min-height: 100%;
  height: 100%;
}

.f-block a {
  color: inherit;
}

.heart-icon {
  color: red;
  animation: heart-beat 1s ease-in-out infinite;
}

:root {
  --size: 100px;
  --frames: 62;
}

input {
  display: none;
}

.like {
  display: block;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  border-radius: 999px;
  overflow: visible;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.hearth {
  background-image: url('https://assets.codepen.io/23500/Hashflag-AppleEvent.svg');
  background-size: calc(var(--size) * var(--frames)) var(--size);
  background-repeat: no-repeat;
  background-position-x: calc(var(--size) * (var(--frames) * -1 + 2));
  background-position-y: calc(var(--size) * 0.02);
  width: var(--size);
  height: var(--size);
}

input:checked + .hearth {
  animation: like 1s steps(calc(var(--frames) - 3));  
  animation-fill-mode: forwards;
}

@keyframes like {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: calc(var(--size) * (var(--frames) * -1 + 3));
  }
}

@media (hover: hover) {
  .like:hover {
    background-color: #E1255E15;
    .hearth {
      background-position-x: calc(var(--size) * (var(--frames) * -1 + 1));
    }
  }
}

// hashflag-gallery - dribbble - twitter
.hashflag {
  position: fixed;
  display: block;
  right: 114px;
  bottom: 20px;
  img {
    display: block;
    height: 28px;
  }
}
.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
  img {
    display: block;
    height: 28px;
  }
}
.twitter {
  position: fixed;
  display: block;
  right: 64px;
  bottom: 14px;
  svg {
    width: 32px;
    height: 32px;
    fill: #1da1f2;
  }
}


@keyframes heart-beat {
  0% {
    opacity: 0.1;
    font-size: 56px;
  }
  50% {
    font-size: 64px;
    opacity: 1;
  }
  100% {
    opacity: 0.1;
    font-size: 56px;
  }
}

.arrow {
  animation: slide 1s ease-in-out infinite, fade 1s ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 1s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

@keyframes slide {
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
